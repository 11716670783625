import { Component, Prop, Vue } from 'vue-property-decorator';
import Holidays from 'date-holidays';

const hd = new Holidays({types: ['public']})
hd.init('NO');

const leaveDays = 10;
const fullWorkDay = 7.5;

const cache: {
  [key: number]: boolean;
} = {}
function isWorkday(date: Date){
  if (cache[date.valueOf()] === undefined){
    cache[date.valueOf()] = !([0, 6].includes(date.getDay()) || !!hd.isHoliday(date));
  }
  return cache[date.valueOf()];
}
@Component
export default class Permittering extends Vue {
  humanHours(hoursIn: number) {
    const sec_num = Math.round(hoursIn * 60 * 60);
    const hours   = Math.floor(sec_num / 3600);
    const minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    const seconds = sec_num - (hours * 3600) - (minutes * 60);
    return hours.toFixed(0).padStart(2, '0')+':'+minutes.toFixed(0).padStart(2, '0')+':'+seconds.toFixed(0).padStart(2, '0');
}
  positionPercentUnthrottled = 100;
  positionPercentThrottled = 100;
  positionPercentThrottle = 0;
  get positionPercent() {
    return this.positionPercentUnthrottled;
  }
  set positionPercent(val: number) {
    val = Math.max(1, Math.min(Number(val), 100));
    this.positionPercentUnthrottled = val;
    if (!this.positionPercentThrottle){
      this.positionPercentThrottled = val;
      this.positionPercentThrottle = setTimeout(() => {
        this.positionPercentThrottled = this.positionPercentUnthrottled;
        this.positionPercentThrottle = 0;
      }, 300);
    }
  }
  leavePercentUnthrottled = 100;
  leavePercentThrottled = 100;
  leavePercentThrottle = 0;
  get leavePercent() {
    return this.leavePercentUnthrottled;
  }
  set leavePercent(val: number) {
    val = Math.max(1, Math.min(Number(val), 100));
    this.leavePercentUnthrottled = val;
    if (!this.leavePercentThrottle){
      this.leavePercentThrottled = val;
      this.leavePercentThrottle = setTimeout(() => {
        this.leavePercentThrottled = this.leavePercentUnthrottled;
        this.leavePercentThrottle = 0;
      }, 300);
    }
  }
  fromDate = new Date().toISOString().substr(0, 10);
  fromDateDialog = false;
  get workdays(){
    return leaveDays / (this.leavePercentThrottled / 100)
  }
  get toDate(){
    const date = new Date(this.fromDate);
    let left = this.workdays;
    while (left > 0){
      date.setDate(date.getDate() + 1);
      if (!isWorkday(date)){
        continue;
      }
      left --;
    }
    return date.toISOString().substr(0, 10);
  }
  get toDateLabel(){
    const partialDay = this.workdays % 1;
    if (partialDay){
      return 'Lønnsplikt til';
    }
    return 'Lønnsplikt til siste arbeidsdag før';
  }
  get workhoursRegular(){
    return fullWorkDay*this.positionPercentThrottled/100;
  }
  get workhoursOnLeave(){
    return this.workhoursRegular*(1-this.leavePercentThrottled/100)
  }
  get workQuota(){
    return this.workhoursOnLeave * this.workdays;
  }
}